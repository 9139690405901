header {
  background: #fff;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-bottom: 1px solid #dbdbd4;
  width: 90%;
  margin: 0 auto;
}
header .logo {
  font-size: 30px;
}
header .logo span {
  color: rgb(249, 43, 43);
}
header nav ul {
  display: flex;
}
header nav ul li a {
  display: inline-block;
  color: #000;
  padding: 5px 0;
  margin: 0 10px;
  border: 3px solid transparent;
  transition: 0.2s;
}
header nav ul li a:hover,
header nav ul li a.active {
  color: rgb(249, 43, 43);
}
.hamburger {
  cursor: pointer;
  display: none;
}
.hamburger div {
  width: 30px;
  height: 3px;
  margin: 5px 0;
  background: #000;
}
@media only screen and (max-width: 900px) {
  header {
    padding: 0 30px;
  }
  header .logo {
    font-size: 15px;
  }
}
@media only screen and (max-width: 700px) {
  .hamburger {
    display: block;
  }
  header nav {
    position: absolute;
    width: 100%;
    left: -100%;
    top: 70px;
    width: 100%;
    background: #fff;
    padding: 30px;
    transition: 0.3s;
  }
  header #nav_check:checked ~ nav {
    left: 0;
  }
  header nav ul {
    display: block;
  }
  header nav ul li a {
    margin: 5px 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
.container {
  display: flex;
  margin-top: 10px;
}
* {
  font-family: "Jost", cursive;
}
.hero_background {
  background: url("https://res.cloudinary.com/dgnwzvknh/image/upload/v1705583357/assets/towfiqu-barbhuiya-jpqyfK7GB4w-unsplash_nsvnnl.jpg")
    center/cover no-repeat;
  width: 100%;
  height: 70vh;
}
#password::-webkit-text-security-disc {
  font-size: 20px; /* Adjust the font size as needed */
}
@media only screen and (max-width: 900px) {
  .hero_background {
    background: url("https://res.cloudinary.com/dgnwzvknh/image/upload/v1705583357/assets/towfiqu-barbhuiya-jpqyfK7GB4w-unsplash_nsvnnl.jpg")
      center/cover no-repeat;
    width: 100%;
    height: 45vh;
  }
}
